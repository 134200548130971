.container-nav-logo img {
  object-fit: cover;
  height: 0.9rem;
  margin-left: 45%;
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
}

.background-color-nav {
  background-color: white;
}

.background-color-nav > div {
  padding: 0.4rem 0rem;
}

.container-input-search input {
  border: 2px solid rgb(157, 157, 157);
  border-end-end-radius: 9px;
  border-top-right-radius: 9px;
  -webkit-border-end-end-radius: 9px;
  -webkit-border-top-right-radius: 9px;
  border-left: 0px;
  padding-left: 0 !important;
  width: 7rem;
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
}

.container-input-search input:focus {
  outline: none;
  border: 2px solid rgb(157, 157, 157);
  border-end-end-radius: 9px;
  border-top-right-radius: 9px;
  -webkit-border-end-end-radius: 9px;
  -webkit-border-top-right-radius: 9px;
  border-left: 0px;
}

.container-input-search input::placeholder,
.container-input-search input::-webkit-input-placeholder {
  font-family: "Gotham", sans-serif;
  color: black;
  text-align: center;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 600;
  line-height: 0.7 !important;
}

.container-input-search button {
  background-color: white !important;
  width: 2rem;
  border: 2px solid rgb(157, 157, 157);
  border-right: 0px;
  border-top-left-radius: 9px;
  border-bottom-left-radius: 9px;
}

.container-input-search button svg {
  color: rgb(157, 157, 157);
}

.container-nav-admin {
  background-color: rgb(230, 230, 230);
}

.container-options-admin a {
  margin: 0;
  margin: 1rem 10%;
  line-height: 1;
  font-weight: 700;
  font-size: 3vw;
  text-align: center;
  transform: scaleY(1.05);
  -webkit-transform: scaleY(1.05);
  text-decoration: none;
  transition: all 0.3s ease-in-out;
  color: rgb(222, 0, 0);
}

.container-options-admin a:hover {
  color: rgb(90, 90, 90);
}

.container-btn-login {
  width: 20%;
}

.container-btn-login a {
  color: rgb(222, 0, 0);
  transition: all 1s ease-in-out;
  right: 47%;
}

.container-btn-login a:hover {
  color: rgb(90, 90, 90);
}

@media (min-width: 1340px) {
  .container-options-admin a {
    font-size: 2.3rem;
  }
}

@media (max-width: 575px) {
  .container-btn-login a {
    right: 5%;
  }
}

@media (max-width: 740px) {
  .container-nav-logo img {
    height: 0.75rem;
  }
}

@media (max-width: 690px) {
  .container-nav-logo img {
    height: 0.65rem;
    margin-left: 45%;
  }
}
@media (max-width: 610px) {
  .container-input-search input {
    margin-right: 3%;
  }
}
@media (max-width: 575px) {
  .container-nav-logo img {
    margin-left: 6%;
  }

  .container-input-search input {
    margin-right: 0%;
  }
}
@media (max-width: 510px) {
  .container-input-search input {
    margin-right: 2%;
  }
}
@media (max-width: 485px) {
  .container-input-search input {
    margin-right: 4%;
  }
}
@media (max-width: 430px) {
  .container-input-search form {
    width: 100%;
  }
  .container-input-search input {
    width: 70%;
    margin-right: 0% !important;
  }
}
@media (max-width: 380px) {
  .container-nav-logo {
    width: 65% !important;
  }

  .container-input-search form {
    height: 1.5rem;
  }

  .container-input-search button svg {
    font-size: 0.8rem;
    position: relative !important;
    bottom: 10% !important;
  }
  .container-input-search input {
    width: 65%;
    font-size: 0.8rem;
  }

  .container-input-search input::placeholder,
  .container-input-search input::-webkit-input-placeholder {
    font-size: 0.6rem;
    font-weight: 600;
    text-align: center;
    position: relative !important;
    bottom: 10% !important;
  }
}

@media (max-width: 310px) {
  .container-nav-logo {
    width: 100% !important;
  }

  .container-nav-logo img {
    margin: 0.5rem 0rem;
    margin-left: 40%;
  }

  .container-input-search {
    width: 100% !important;
  }

  .container-input-search form {
    display: flex;
    justify-content: center;
  }

  .container-input-search input {
    width: 80%;
  }
}
