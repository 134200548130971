p {
  font-family: "Gotham", serif;
}

.container-about-us {
  padding: 4vw 0;
  background-color: rgb(102, 102, 102);
}

.container-about-us > div {
  margin-left: auto;
  margin-right: auto;
}

.p-about-us {
  transition: all 1s ease-in-out;
  font-size: 4vw;
  font-weight: 100;
  transform: scaleY(1.1);
  margin: 0rem 1rem;
}

.text-about-us {
  transition: all 1s ease-in-out;
  margin: 0;
  font-size: 1.7vw;
  line-height: 1.2;
  width: 50vw;
}

@media (min-width: 1340px) {
  .p-about-us {
    font-size: 3.5rem;
  }

  .text-about-us {
    font-size: 1.5rem;
    width: 70rem;
  }
}

@media (max-width: 740px) {
  .container-about-us {
    padding: 1.7rem 0;
  }
  .p-about-us {
    font-size: 4.5vw;
  }
  .text-about-us {
    font-size: 2vw;
  }
}

@media (max-width: 575px) {
  .container-about-us {
    padding: 1.4rem 0;
  }
  .p-about-us {
    font-size: 5.7vw;
    text-align: center;
    margin: 0 auto !important;
  }
  .text-about-us {
    text-align: center;
    font-size: 2.7vw;
  }
}

@media (max-width: 415px) {
  .container-about-us {
    padding: 1.6rem 0;
  }
  .p-about-us {
    font-size: 5.8vw;
    text-align: center;
    margin: 0 auto !important;
  }
  .text-about-us {
    width: 85vw;
    text-align: center;
    font-size: 3.3vw;
    margin-top: 0 !important;
    padding-bottom: 0.5rem;
  }
}
