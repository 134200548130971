.title-our-projects {
  background-color: rgb(222, 0, 0);
}

.title-our-projects p {
  margin: 0;
  margin: 0.3rem 0rem;
  color: #fff;
  font-weight: bold;
  font-size: 1.6rem;
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
}

.static-img{
  max-height: 40vh;
}

.static-img img{
  object-fit: cover;
  width: 100%;
}

.title-habitar p {
  margin: 0;
  margin: 0.5rem 0rem;
  color: rgb(163, 163, 163);
  font-weight: 100;
  font-size: 4.5rem;
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
}

@media (max-width: 1000px) {
  .title-habitar p {
    font-size: 4rem;
  }
}

@media (max-width: 720px) {
  .title-habitar p {
    font-size: 3rem;
  }
  .static-img{
    height: 30vh;
  }
}

@media (max-width: 470px) {
  .title-our-projects p {
    font-size: 1.5rem;
  }
  .title-habitar p {
    font-size: 2.5rem;
  }
}

@media (max-width: 390px) {
  .title-our-projects p {
    font-size: 1.2rem;
  }
  .title-habitar p {
    font-size: 1.7rem;
  }
}
