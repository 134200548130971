.container-result-search-not-found {
  background-color: grey;
  margin-left: auto;
  margin-right: auto;
  max-width: 600px !important;
}

.container-result-search-not-found p {
  text-transform: uppercase;
  font-weight: 600;
  text-align: center;
  margin: 0;
  margin: 1rem 0;
  color: white;
}

.container-your-research {
  max-width: 1340px;
  margin: 1rem 2rem;
}

.container-your-research p:first-child {
  text-transform: uppercase;
  margin: 0 0.2rem;
}
