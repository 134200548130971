.row-view-our-team > .col {
  height: 17vw;
}

.container-detail-our-team {
  margin: 1.5vw 0;
}

.our-team-title {
  background-color: rgb(270, 0, 0) !important;
}

.col-contain-info-team {
  padding: 2rem;
}

.contain-photo-team {
  height: 11.5vw;
}

.logo-faces-our-team {
  transition: all 0.5s ease-in-out;
  width: 10.1vw !important;
  cursor: pointer;
}

h4 {
  font-family: "Gotham Narrow", sans-serif;
}

.nombre,
.cargo {
  font-family: "Gotham Narrow", sans-serif;
  margin: 0;
  font-weight: 300;
  font-size: 1.3vw;
}

.contain-photo-team > img {
  transition: all 1s ease-in;
  position: relative;
  top: 0;
  z-index: 2;
}

.active-contain-photo {
  transition-property: 3rem;
  opacity: 1;
  top: -0.2rem;
}

.detail-person {
  height: 0;
  position: relative;
  top: -4rem;
  opacity: 0;
  visibility: hidden;
  transition: all 1s ease-in-out;
}
.detail-person p {
  transition: all 0.5s ease-in-out;
  opacity: 0;
  visibility: visible;
}

.active-detail-person {
  height: max-content;
  top: 0;
  opacity: 1;
  visibility: visible;
}
.active-detail-person p {
  opacity: 1;
  visibility: visible;
}

@media (min-width: 1340px) {
  .row-view-our-team > .col {
    height: 14rem;
  }

  .contain-photo-team {
    height: 10rem;
  }

  .logo-faces-our-team {
    width: 9rem !important;
  }

  .nombre,
  .cargo {
    font-size: 1.18rem;
  }
}

@media (max-width: 710px) {
  .contain-photo-team {
    height: 11.8vw;
  }

  .logo-faces-our-team {
    width: 10.1vw !important;
  }

  .nombre,
  .cargo {
    font-size: 1.5vw;
  }
}

@media only screen and (max-width: 600px) {
  .row-view-our-team > .col {
    height: 28.5vw;
  }

  .contain-photo-team {
    height: 18vw;
  }

  .logo-faces-our-team {
    width: 16vw !important;
  }

  .nombre {
    font-size: 2.7vw;
  }
  .cargo {
    font-size: 2.1vw;
  }
}

@media (min-width: 451px) {
  .carousel-our-team {
    display: none !important;
  }
}

@media only screen and (max-width: 450px) {
  .row-view-our-team {
    display: none !important;
  }

  .carousel-our-team .carousel-indicators {
    display: none !important;
  }

  .contain-photo-team {
    height: 43vw;
  }

  .logo-faces-our-team {
    width: 40vw !important;
  }

  .nombre {
    font-size: 5vw;
  }
  .cargo {
    font-size: 4vw;
  }

  .carousel-our-team svg {
    height: 7vw !important;
    color: red !important;
  }
}
