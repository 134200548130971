p {
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

.carousel-item-custom {
  position: relative;
}

.carousel-control-prev svg,
.carousel-control-next svg {
  color: white !important;
  height: 1.3rem !important;
}

.container-carousel-item-logo img {
  height: 20vw;
  transition: all 0.5s ease-in-out;
  object-fit: cover;
}

.logo-estudio-slider {
  transition: all 0.5s ease-in-out;
  height: 4.1vw !important;
  margin-bottom: 45%;
}

.container-carousel-item-logo img:hover {
  height: 21vw;
}

.container-carousel-item-logo {
  transition: all 0.5s ease-in-out;
  position: absolute;
  left: 13%;
  bottom: 22%;
}
.container-carousel-item-desc {
  transition: all 0.5s ease-in-out;
  position: absolute;
  right: 11%;
  bottom: 38%;
  width: 24vw;
}

.container-carousel-item-desc p {
  color: white;
  font-size: 3.7vw;
  margin: 0;
  line-height: 1;
  letter-spacing: 0.04em;
  font-weight: 400;
  transition: all 1s ease-in-out;
}

.container-carousel-item-desc a {
  text-decoration: none;
  text-transform: uppercase;
  color: black;
  background-color: white;
  padding: 0.23rem 0.5rem;
  border-radius: 5px;
  margin-top: 0.5rem;
  font-size: 1vw;
  font-weight: 500;
  width: max-content;
  transition: all 1s ease-in-out;
}

.container-carousel-item-desc a:hover {
  color: rgb(222, 0, 0);
}

.container-carousel-item-desc a svg {
  padding-left: 0.4rem;
  padding-top: 0.2vw;
}

.carousel-indicators button {
  height: 0.5rem !important;
  width: 0.5rem !important;
  border-radius: 100%;
  border: 4px solid rgb(222, 0, 0) !important;
  background-color: transparent !important;
}
.carousel-indicators .active {
  background-color: white !important;
}

@media (min-width: 1340px) {
  .container-carousel-item-logo img {
    height: 16rem;
  }
  .container-carousel-item-logo img:hover {
    height: 17rem;
  }

  .logo-estudio-slider {
    height: 3.5rem !important;
  }

  .container-carousel-item-desc {
    width: 20rem;
  }
  .container-carousel-item-desc p {
    font-size: 3rem;
  }

  .container-carousel-item-desc a {
    padding: 0.25rem 0.55rem;
    font-size: 0.7rem;
  }

  .container-carousel-item-desc a svg {
    padding-bottom: 0.2rem;
  }
}

@media (max-width: 552px) {
  .container-carousel-item-desc p {
    font-size: 3.1vw;
  }
  .container-carousel-item-desc a {
    font-size: 1.1vw;
  }
}

@media (max-width: 480px) {
  .carousel-item-custom > img {
    object-fit: cover;
    object-position: center;
    height: 15rem;
  }

  .container-carousel-item-desc p {
    font-size: 4.5vw;
    width: 32vw !important;
    padding: 0.3rem;
    border-radius: 10px;
  }
  .container-carousel-item-desc a {
    font-size: 2.4vw !important;
  }

  .container-carousel-item-desc {
    left: 12%;
    right: 0%;
    bottom: 38%;
    width: 80vw;
  }
  .logo-estudio-slider {
    margin-bottom: 0%;
    margin-top: 100%;
    margin-left: 165%;
  }
}

@media (max-width: 400px) {
  .container-carousel-item-desc a {
    font-size: 2vw !important;
    font-weight: 700 !important;
    line-height: 1.7;
  }

  .carousel-indicators button {
    width: 0.6rem !important;
  }

  .carousel-control-prev span,
  .carousel-control-next span {
    width: 4.5vw !important;
  }
}
