.title-our-projects {
  background-color: rgb(222, 0, 0);
}

.title-our-projects p {
  margin: 0;
  margin: 0.3rem 0rem;
  color: #fff;
  font-weight: bold;
  font-size: 1.6rem;
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
}

.title-urban-solutions {
  font-size: 3rem;
  color: rgb(77, 77, 77);
}

.title-urban-solutions p {
  margin: 0;
  margin: 0.5rem 0rem;
  color: rgb(163, 163, 163);
  font-weight: 100;
  font-size: 4.5rem;
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
}

.container-btn-urban-solution {
  margin: 0;
}

.container-btn-urban-solution a {
  width: 37vw;
}

@media (min-width: 1100px) {
  .container-btn-urban-solution a {
    width: 28.5rem;
    font-size: 1.5rem;
  }
}

@media (max-width: 1000px) {
  .title-habitar p {
    font-size: 4rem;
  }
  .title-urban-solutions p {
    font-size: 3.3rem;
  }
}

@media (max-width: 720px) {
  .title-habitar p {
    font-size: 3rem;
  }
  .title-urban-solutions p {
    font-size: 2.3rem;
  }
}

@media (max-width: 640px) {
  .container-btn-urban-solution a {
    font-size: 2.5vw;
  }
}

@media (max-width: 470px) {
  .title-our-projects p {
    font-size: 1.5rem;
  }
  .title-urban-solutions p {
    font-size: 2.3rem;
  }
}

@media (max-width: 390px) {
  .title-our-projects p {
    font-size: 1.2rem;
  }
  .title-urban-solutions p {
    font-size: 1.5rem;
  }
}
