.p-error-text {
  font-size: 3rem;
  font-weight: 400;
  text-align: center;
  color: rgb(222, 0, 0);
}

.p-error-help {
  font-size: 2rem;
  text-align: center;
  line-height: 1.1;
}

.btn-back-home-error-404 {
  margin: 2rem auto;
}

.btn-back-home-error-404 a {
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  background-color: rgb(222, 0, 0);
  color: #fff;
  width: 11rem;
  padding: 0.4rem 0rem;
  text-decoration: none;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  border: 3px solid transparent;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.btn-back-home-error-404 a:hover {
  color: rgb(222, 0, 0);
  background-color: rgb(230, 230, 230);
  border: 3px solid rgb(222, 0, 0);
}
