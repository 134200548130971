.thumbnail-slider-wrap > div {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 3vw;
}

.thumbnail-slider-wrap .slick-track .slick-slide {
  text-align: center;
}
.thumbnail-slider-wrap .slick-track .slick-slide img {
  width: 70%;
}

.product-title-page {
  background-color: #fff;
  margin-bottom: 2rem;
}

.product-title-page p {
  color: rgb(128, 128, 128);
}

.container-btn-contact-product-detail a:hover {
  color: rgb(222, 0, 0);
  background-color: rgb(230, 230, 230);
  border: 3px solid rgb(222, 0, 0);
}

.form-formik-small small {
  color: rgb(222, 0, 0) !important;
  text-transform: uppercase;
  font-weight: 500;
}

.slick-slide-image {
  width: 100%;
}

.img-first-slider {
  width: 43vw;
  height: 42vw !important;
  object-fit: cover;
}

.contain-p-product {
  width: 45vw;
}

.contain-p-product p {
  width: 25vw;
  font-weight: 700;
  font-size: 2.15vw;
  text-transform: uppercase;
  text-align: center;
  color: #fff;
  padding: 0.3rem 0rem;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.p-product-type {
  background-color: rgb(77, 77, 77);
}

.p-product-status {
  background-color: rgb(222, 0, 0);
}

.product-name-page {
  margin: 0;
  margin: 0.1rem 0rem;
  font-weight: 900;
  font-size: 2.5vw;
  text-align: center;
  transform: scaleY(1.05);
  -webkit-transform: scaleY(1.05);
  color: rgb(77, 77, 77);
  text-align: start;
}

.row-container-product-detail-page {
  margin: 0;
  margin: 1.5vw 0 !important;
}

.row-container-product-detail-page p {
  font-size: 1.7vw;
}

.container-desc-product-detail {
  height: 18vw;
  width: 45vw;
  overflow-y: scroll;
}

.container-desc-product-detail p {
  font-size: 1.8vw;
  padding: 0;
  line-height: 1.2;
}

.row-price-and-btn-product-detail {
  margin: 0 !important;
  margin: 2vw 0 !important;
  margin-right: 4vw !important;
}

.container-product-detail-price p {
  text-align: start;
  font-size: 2.4vw;
}

.container-btn-contact-product-detail a {
  font-weight: 700;
  font-size: 1.8vw;
  text-transform: uppercase;
  text-align: center;
  background-color: rgb(222, 0, 0);
  color: #fff;
  width: 15vw;
  height: 3.9vw;
  padding: 0.13rem 0.4rem;
  text-decoration: none;
  border-radius: 25px !important;
  -webkit-border-radius: 10px;
  border: 3px solid transparent;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
}

.thumbnail-slider-wrap img {
  object-fit: cover;
  width: 12.1vw !important;
  padding: 0 0.1rem;
  height: 10vw;
  transition: 1s ease-in-out;
}

.slick-next:before,
.slick-prev:before {
  content: "" !important;
}

.next-slick-arrow,
.prev-slick-arrow,
.next-slick-arrow:hover,
.prev-slick-arrow:hover {
  background-color: rgb(222, 0, 0) !important;
  border-radius: 200%;
  border: 0;
  color: #fff;
  font-size: 1.6rem;
}

.slick-dots {
  display: none !important;
}

.container-products-list {
  background-color: #fff;
}

.container-products-list > div {
  margin: 0 !important;
  margin-left: auto !important;
  margin-right: auto !important;
  padding-bottom: 3vw !important;
  max-height: 75vw;
  overflow-y: scroll;
}

.thumbnail-slider-wrap-display-none-custom > div > div > div > div {
  width: 170px !important;
}

.div-slick-img-overflow {
  overflow: hidden !important;
}

.thumbnail-slider-wrap-display-none-custom .slick-slide img:hover,
.thumbnail-slider-wrap-display-custom .slick-slide img:hover {
  transform: scale(1.05);
  cursor: pointer;
  filter: grayscale(50%);
}

.thumbnail-slider-wrap-display-none-custom > div > div > div {
  display: flex;
  justify-content: center;
}

.thumbnail-slider-wrap-display-none-custom .slick-track {
  height: 100%;
}

.img-first-slider-custom {
  object-fit: cover;
}

@media (min-width: 1340px) {
  .thumbnail-slider-wrap-display-none-custom .slick-track {
    height: 100% !important;
  }

  .thumbnail-slider-wrap > div {
    margin-bottom: 2.6rem;
  }

  .img-first-slider {
    width: 37rem;
    height: 36rem !important;
  }

  .contain-p-product {
    width: 37.5rem;
  }

  .contain-p-product p {
    width: 25rem;
    font-size: 1.85rem;
  }

  .product-name-page {
    font-size: 2.2rem;
  }

  .row-container-product-detail-page {
    margin: 0;
    margin: 1.5rem 0 !important;
  }

  .row-container-product-detail-page p {
    font-size: 1.4rem;
  }

  .container-desc-product-detail {
    height: 14.5rem;
    width: 37.5rem;
  }

  .container-desc-product-detail p {
    font-size: 1.5rem;
  }

  .row-price-and-btn-product-detail {
    margin: 2rem 0 !important;
    margin-right: 4rem !important;
  }

  .container-product-detail-price p {
    font-size: 2rem;
  }

  .container-btn-contact-product-detail a {
    font-size: 1.5rem;
    width: 12.7rem;
    height: 3.4rem;
  }
  .thumbnail-slider-wrap img {
    width: 10.4rem !important;
    height: 8.5rem !important;
  }

  .container-products-list > div {
    max-height: 65rem;
  }
}

@media (max-width: 1000px) {
  .thumbnail-slider-wrap img {
    width: 14vw !important;
    height: 13vw;
  }
}

@media (min-width: 768px) {
  .thumbnail-slider-wrap-display-custom {
    display: none;
  }
}

@media (max-width: 767px) {
  .thumbnail-slider-wrap-display-none-custom {
    display: none;
  }

  .thumbnail-slider-wrap-display-custom {
    margin-top: 2vw;
  }

  .img-first-slider {
    width: 80vw;
    height: 50vw !important;
  }

  .contain-p-product {
    width: 95vw;
  }

  .contain-p-product p {
    width: 47vw;
    font-size: 4.5vw;
  }

  .product-name-page {
    font-size: 5vw;
  }

  .row-container-product-detail-page p {
    font-size: 3.2vw;
  }

  .container-desc-product-detail {
    max-height: 30vw;
    width: 95vw;
  }

  .container-desc-product-detail p {
    font-size: 2.6vw;
  }

  .row-container-product-detail-page {
    margin: 3.5vw 0 !important;
  }

  .container-product-detail-price p {
    font-size: 4.5vw;
  }

  .container-btn-contact-product-detail a {
    font-size: 3vw;
    width: 27vw;
    height: 6vw;
  }

  .thumbnail-slider-wrap img {
    width: 16vw !important;
    height: 15vw;
  }
}

@media (max-width: 540px) {
  .thumbnail-slider-wrap img {
    width: 16.3vw !important;
    height: 18vw;
  }
}

@media (max-width: 390px) {
  .next-slick-arrow,
  .prev-slick-arrow {
    font-size: 4.5vw;
    width: 3vw;
    height: 3vw;
  }

  .thumbnail-slider-wrap img {
    width: 25vw !important;
    height: 25vw;
  }

  .container-product-detail-price p {
    font-size: 5.5vw;
  }

  .container-btn-contact-product-detail a {
    font-size: 3.8vw;
    width: 29vw;
    height: 8vw;
  }
}
