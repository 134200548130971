.container-login-user {
  background-color: rgb(102, 102, 102);
  padding: 3rem 0;
  height: calc(100vh - 80px - 42.8px);
}

.container-login-user > .row {
  max-width: 1000px;
  width: 70%;
  margin: 0;
  margin-left: auto;
  margin-right: auto;
}

.title-login p {
  text-transform: uppercase;
  color: #fff;
  font-size: 2.5vw;
  font-weight: 500;
}

.form-login-custom input {
  height: 2.5rem;
  border-radius: 0px;
}

.form-login-custom input::placeholder {
  color: black;
  text-transform: uppercase;
}

.form-login-custom small {
  color: #fff !important;
  text-transform: uppercase;
}

.btn-login-custom,
.btn-google-option-login-register button {
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  background-color: rgb(222, 0, 0);
  color: #fff;
  width: 27%;
  padding: 0.15rem 0rem;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  border: 3px solid transparent;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.btn-login-custom:hover {
  color: rgb(222, 0, 0);
  background-color: rgb(230, 230, 230);
  border: 3px solid rgb(222, 0, 0);
}

.login-register-change {
  transition: all 0.3s ease-in-out;
  text-transform: uppercase;
  text-decoration: none;
  color: #fff;
}

.login-register-change:hover {
  color: #e0e0e0;
}

.btn-google-option-login-register {
  margin-top: 0.9rem;
}
.btn-google-option-login-register button {
  background-color: rgb(128, 128, 128);
  width: 55%;
}
.btn-google-option-login-register button:hover {
  color: rgb(128, 128, 128);
  background-color: #fff;
  border: 3px solid rgb(128, 128, 128);
  width: 55%;
}

@media (max-height: 410px) {
  .container-login-user {
    height: auto !important;
  }
}

@media (max-height: 605px) {
  .container-register-user {
    height: 35vw !important;
  }
}

@media (min-width: 1340px) {
  .title-login p {
    font-size: 2.2rem;
  }
}
@media (max-width: 991px) {
  .title-login p {
    font-size: 2rem;
  }

  @media (max-height: 410px) {
    .container-login-user {
      height: auto !important;
    }
  }

  @media (max-height: 660px) {
    .container-register-user {
      height: 70vw !important;
    }
  }
}
@media (max-width: 700px) {
  .container-login-user > .row {
    width: 85%;
  }
  .btn-login-custom,
  .btn-google-option-login-register button,
  .login-register-change {
    font-size: 2.3vw;
  }
}

@media (max-width: 430px) {
  .btn-login-custom,
  .container-login-register-change,
  .login-register-change {
    width: 100% !important;
    font-size: 3.6vw;
  }

  .btn-google-option-login-register button {
    font-size: 3.6vw;
    width: 100%;
  }
}

@media (max-width: 336px) {
  .title-login p {
    font-size: 8vw;
  }
}
