.container-project {
  max-width: 1340px;
  margin-left: auto;
  margin-right: auto;
}

.img-background {
  background-image: url(/public/Images/nuestrosproyectos-fondo.png);
  background-position: center;
  background-size: cover;
  color: white;
}

.img-custom {
  object-fit: cover;
}

.font-proyectos {
  font-size: 27.2px;
  font-family: "Gotham Black", sans-serif;
  font-weight: bold;
  padding: 0;
  margin: 0;
}

.font-construimos {
  font-size: 53px;
  font-family: "Gotham Black", sans-serif;
  text-transform: uppercase;
  margin: 0;
  padding-top: 24%;
  line-height: 0.7;
}

.font-urbana {
  font-size: 39px;
  font-family: "Gotham Narrow", sans-serif;
  text-transform: uppercase;
  font-weight: 80;
  margin: 0;
  padding: 0;
}

.margin-bot {
  margin-bottom: 16%;
}

.margin-l {
  margin-left: 7%;
  margin-top: 10%;
}

.btn-our-projects a {
  text-decoration: none;
  text-transform: uppercase;
  color: #fff;
  background-color: rgb(222, 0, 0);
  padding: 0.23rem 0.5rem;
  border-radius: 5px;
  margin-top: 0.5rem;
  font-size: 2vw;
  font-weight: 500;
  width: max-content;
  transition: all 1s ease-in-out;
}

.btn-our-projects a:hover {
  background-color: #fff;
  color: rgb(222, 0, 0);
}

@media (min-width: 1200px) {
  .btn-our-projects a {
    font-size: 1.3rem;
  }
}

@media (max-width: 1300px) {
  .btn-width {
    font-size: 1.5vw !important;
  }
  /* .font-proyectos {
    font-size: 2.7vw;
  } */
  .font-construimos {
    font-size: 4vw;
    padding-top: 25%;
  }
  .font-urbana {
    font-size: 2.9vw;
  }
}

@media (max-width: 767px) {
  .btn-our-projects a {
    font-size: 3vw;
  }
  .img-background {
    height: 20rem;
  }
  /* .font-proyectos {
    font-size: 1.8rem;
  } */
  .font-construimos {
    padding-top: 12%;
    font-size: 2.6rem;
  }
  .font-urbana {
    font-size: 1.9rem;
  }
  .margin-l {
    margin-top: 5%;
  }
}

@media (max-width: 470px) {
  .font-proyectos {
    padding-top: 7%;
    font-size: 1.5rem;
  }
  .font-construimos {
    padding-top: 17%;
    font-size: 2.3rem;
  }
  .font-urbana {
    font-size: 1.5rem;
  }

  .btn-our-projects a {
    font-size: 4.5vw;
  }
}

@media (max-width: 330px) {
  .btn-width {
    font-size: 3vw !important;
  }
  .img-background {
    height: 17rem;
  }
  .font-proyectos {
    font-size: 1.2rem;
  }
  .font-construimos {
    padding-top: 20%;
    font-size: 1.9rem;
  }
  .font-urbana {
    font-size: 1.4rem;
  }
  .margin-l {
    margin-top: 5%;
    margin-left: 3%;
  }
}
