p {
  font-family: "Gotham", sans-serif;
}

.title-prop {
  background-color: rgb(102, 102, 102);
}

.title-prop p {
  margin: 0;
  margin: 0.5rem 0rem;
  color: #fff;
  font-weight: bold;
  font-size: 1.7rem;
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
}

.container-card-alquiler {
  margin-top: 2rem;
  width: 94%;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  border-end-start-radius: 40px;
  border-bottom-right-radius: 40px;
  -webkit-border-top-left-radius: 40px;
  -webkit-border-top-right-radius: 40px;
  -webkit-border-end-start-radius: 40px;
  -webkit-border-bottom-right-radius: 40px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  -webkit-box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
}

.container-img-alquiler {
  width: 100%;
  overflow: hidden;
  border-top-left-radius: 40px;
  border-top-right-radius: 40px;
  -webkit-border-top-left-radius: 40px;
  -webkit-border-top-right-radius: 40px;
  transition: all 1s ease-in-out;
  -webkit-transition: all 1s ease-in-out;
}

.container-img-alquiler img {
  width: 100%;
  height: 13.5rem;
  object-fit: cover;
  object-position: center;
  transition: 0.3s ease-in-out;
  -webkit-transition: 0.3s ease-in-out;
}

.container-img-alquiler img:hover {
  transform: scale(1.07);
  -webkit-transform: scale(1.07);
}

.detail-status-alquiler-card {
  width: 100%;
}

.detail-status-alquiler-card p {
  text-transform: uppercase;
  margin: 0;
  margin: 0.5rem 0rem;
  font-weight: bold;
  font-size: 1.7rem;
  text-align: center;
}

.detail-status-en-venta {
  background-color: rgb(222, 0, 0);
}

.detail-status-en-venta P {
  color: #fff;
}

.detail-status-en-alquiler {
  background-color: rgb(33, 10, 10);
}

.detail-status-en-alquiler p {
  color: rgb(128, 128, 128);
}

.container-card-available img {
  filter: grayscale(100%);
}

.container-card-available .detail-status-alquiler-card {
  background-color: grey;
}

.container-card-available .detail-status-alquiler-card p {
  color: #fff;
}

.container-card-available .container-details-card-alquiler p {
  color: grey;
}

.container-card-available .container-details-card-alquiler a {
  background-color: grey;
}

.container-card-available .container-details-card-alquiler a:hover {
  color: grey;
  border: 3px solid grey;
}

.container-details-card-alquiler {
  background-color: #fff;
  width: 100%;
  border-end-start-radius: 40px;
  border-bottom-right-radius: 40px;
  -webkit-border-end-start-radius: 40px;
  -webkit-border-bottom-right-radius: 40px;
}

.container-details-card-alquiler > p {
  margin: 0;
  margin: 1rem 1%;
  line-height: 1;
  font-weight: 900;
  font-size: 1.7rem;
  text-align: center;
  transform: scaleY(1.05);
  -webkit-transform: scaleY(1.05);
  color: rgb(77, 77, 77);
}

.container-text-area-alquiler p,
.card-price-alquiler p {
  font-size: 1.3rem;
  margin: 0;
  font-weight: 400;
  line-height: 1.2;
}

.p-size-area {
  color: rgb(77, 77, 77);
  font-weight: 700 !important;
  margin: 0 0.2rem !important;
}

.card-price-alquiler p {
  margin: 0.5rem 0rem;
  color: rgb(222, 0, 0);
  font-weight: 700 !important;
  text-align: center;
}

.btn-info-card-alquiler {
  margin-bottom: 1.8rem;
}

.btn-info-card-alquiler a {
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  background-color: rgb(222, 0, 0);
  color: #fff;
  width: 8rem;
  padding: 0.15rem 0rem;
  text-decoration: none;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  border: 3px solid transparent;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.btn-info-card-alquiler a:hover {
  color: rgb(222, 0, 0);
  background-color: rgb(230, 230, 230);
  border: 3px solid rgb(222, 0, 0);
}

@media (max-width: 767px) {
  .container-card-alquiler {
    width: 80%;
  }
}

@media (max-width: 600px) {
  .container-card-alquiler {
    width: 90%;
  }
}

@media (max-width: 470px) {
  .container-card-alquiler {
    width: 95%;
  }

  .title-prop p {
    font-size: 1.5rem;
  }
}

@media (max-width: 390px) {
  .container-card-alquiler {
    width: 98%;
  }

  .title-prop p {
    font-size: 1.2rem;
  }
}
