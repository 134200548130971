footer {
  background-color: rgb(222, 0, 0);
}

.form-contact-footer {
  margin: 0rem 15%;
}

.form-contact-footer input,
.form-contact-footer textarea {
  margin: 0;
  border: 0 !important;
  border-radius: 0 !important;
}

.form-contact-footer > div {
  margin-bottom: 0.8rem;
}

.col-social-network {
  display: flex;
  justify-content: end;
}

.col-social-network > div {
  width: 70%;
}

.container-img-footer img {
  width: 100%;
}

.container-btn-submit-contact button {
  margin: 0;
  margin-left: 2rem;
  background-color: transparent;
  color: white;
  border: 0;
  text-transform: uppercase;
  font-family: "Gotham", sans-serif;
  font-weight: 600;
}

.footer-bottom-custom {
  height: 5rem;
  background-color: rgb(77, 77, 77);
}

.footer-bottom-custom img {
  object-fit: contain;
  height: 1.8rem;
}

.contain-logos-sn {
  margin-right: 2vw;
}

.contain-logos-sn svg {
  font-size: 2.6vw;
  margin-left: 0.5vw;
  margin-right: 0.5vw;
  padding: 0.4rem;
  width: 2vw;
  height: 2vw;
  border-radius: 100px !important;
  background-color: rgb(60, 61, 64);
  color: #fff;
  transition: all 0.5s ease-in-out;
}

.contain-logos-sn svg:hover {
  color: rgb(60, 61, 64);
  background-color: #fff;
}

/* Media queries */

@media (min-width: 1340px) {
  .contain-logos-sn svg {
    font-size: 2.4rem;
    width: 1.7rem;
    height: 1.7rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
}

@media (min-width = 576px) {
  .columna-img {
    justify-content: center !important;
    align-items: center !important;
  }
}

@media (max-width: 575px) {
  .contain-logos-sn {
    margin-right: 9vw;
  }

  .contain-logos-sn svg {
    width: 5vw;
    height: 5vw;
    font-size: 5.2vw;
    margin-left: 0.9vw;
    margin-right: 0.9vw;
  }

  .container-img-footer img {
    margin-left: 3vw;
  }
}

@media (min-width = 300px) {
  .columna-img {
    justify-content: center !important;
    align-items: center !important;
  }
}

@media (max-width: 980px) {
  .form-contact-footer {
    margin: 0rem 5%;
  }

  .col-social-network {
    display: flex;
    justify-content: center;
  }
}

@media (max-width: 720px) {
  .form-contact-footer {
    margin: 0rem 9%;
    margin-left: 0;
  }
}

@media (max-width: 575px) {
  .col-social-network {
    margin-top: 2rem !important;
    display: flex;
    justify-content: center;
  }

  .container-img-footer {
    display: flex;
    justify-content: center;
  }

  .container-img-footer img {
    width: 90%;
  }

  .form-contact-footer {
    margin: 0rem 6.5%;
  }
}
