.div-btn-add-product a {
  font-weight: 700;
  text-transform: uppercase;
  text-align: center;
  background-color: rgb(222, 0, 0);
  color: #fff;
  width: 100%;
  padding: 0.15rem 0rem;
  text-decoration: none;
  border-radius: 10px;
  -webkit-border-radius: 10px;
  border: 3px solid transparent;
  transition: all 0.3s ease-in-out;
  -webkit-transition: all 0.3s ease-in-out;
}

.div-btn-add-product a:hover {
  color: rgb(222, 0, 0);
  background-color: rgb(230, 230, 230);
  border: 3px solid rgb(222, 0, 0);
}

.table-crud-products th,
.table-crud-products tr {
  width: 90% !important;
  font-size: 90% !important;
}
