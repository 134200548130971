.container-networks-share svg {
  width: 90%;
  height: 90%;
  margin-left: 3%;
  margin-right: 3%;
}

.offcanvas {
  width: 50%;
  margin-left: auto;
  margin-right: auto;
  padding: 0.5rem;
  height: 10rem !important;
}

.contain-btn-share button {
  font-size: 2rem;
  margin-left: 20%;
  border: 0px;
  background-color: transparent;
  color: red;
  transition: all 0.5s ease-in-out;
}

.contain-btn-share button:hover {
  color: rgb(75, 26, 26);
}

@media (max-width: 800px) {
  .offcanvas {
    width: 70% !important;
  }
}
@media (max-width: 700px) {
  .offcanvas {
    width: 80% !important;
  }
}
@media (max-width: 550px) {
  .offcanvas {
    width: 100% !important;
  }
}
